<template>
  <v-container fluid>
    <v-overlay v-if="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-form ref="form" v-model="valid" class="ma-4" v-if="law">
      <v-row>
        <v-col class="pb-0">
          <v-text-field
            v-model="law[0].value"
            :placeholder="law[0].item"
            persistent-placeholder
            outlined
            :rules="rules.required"
          >
            <template v-slot:label>
              {{ law[0].item }}<span class="ml-2 red--text">必須</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="law[1].value"
            :label="law[1].item"
            :placeholder="law[1].item"
            persistent-placeholder
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="law[2].value"
            :label="law[2].item"
            :placeholder="law[2].item"
            persistent-placeholder
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="law[3].value"
            :label="law[3].item"
            :placeholder="law[3].item"
            persistent-placeholder
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="law[4].value"
            :label="law[4].item"
            :placeholder="law[4].item"
            persistent-placeholder
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="law[5].value"
            :label="law[5].item"
            :placeholder="law[5].item"
            persistent-placeholder
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="law[6].value"
            :label="law[6].item"
            :placeholder="law[6].item"
            persistent-placeholder
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="law[7].value"
            :label="law[7].item"
            :placeholder="law[7].item"
            persistent-placeholder
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-textarea
            v-model="law[8].value"
            :label="law[8].item"
            :placeholder="law[8].item"
            persistent-placeholder
            rows="5"
            outlined
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-textarea
            v-model="law[9].value"
            :label="law[9].item"
            :placeholder="law[9].item"
            persistent-placeholder
            rows="5"
            outlined
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-textarea
            v-model="law[10].value"
            :label="law[10].item"
            :placeholder="law[10].item"
            persistent-placeholder
            rows="5"
            outlined
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-textarea
            v-model="law[11].value"
            :label="law[11].item"
            :placeholder="law[11].item"
            persistent-placeholder
            rows="5"
            outlined
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-textarea
            v-model="law[12].value"
            :label="law[12].item"
            :placeholder="law[12].item"
            persistent-placeholder
            rows="5"
            outlined
          >
          </v-textarea>
        </v-col>
      </v-row>

      <!-- ボタン -->
      <v-row class="mt-16">
        <v-col cols="12" class="pt-0 pb-0">
          <v-btn
            :disabled="!valid"
            class="primary mt-4"
            large
            block
            @click="onClickRegister"
            >特定商取引法を更新する</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <!-- snackbar -->
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      outlined
      right
    >
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// [前提] この順番で項目表示する
const TITLES = [
  "販売事業者名",
  "販売事業者所在地",
  "代表者または運営統括責任者",
  "代表者または運営統括責任者（フリガナ）",
  "連絡先／ホームページ",
  "連絡先／電子メール",
  "連絡先／TEL",
  "販売価格帯",
  "商品等の引き渡し時期・発送方法",
  "代金の支払時期および方法",
  "商品代金以外に必要な費用",
  "返品の取扱条件",
  "不良品の取扱条件",
];
const createDefaultItems = () => {
  return TITLES.map((title) => {
    return {
      item: title,
      value: "",
    };
  });
};

export default {
  name: "Law",
  components: {},
  props: {},
  data: () => ({
    law: null,

    // form
    valid: true,
    rules: {
      required: [(v) => !!v || "必須入力です"],
    },

    // snackbar
    snack: false,
    snackColor: "",
    snackText: "",
  }),
  computed: {
    ...mapGetters(["progress", "about"]),
  },
  watch: {
    about: {
      handler: function (newValue) {
        this.law = newValue?.law ?? createDefaultItems();
      },
    },
  },
  async mounted() {
    this.fetchAbout();
  },
  methods: {
    ...mapActions(["fetchAbout", "updateAbout"]),
    async onClickRegister() {
      console.log("register", this.about);
      try {
        // 更新処理
        const dbData = this.about;
        dbData.law = this.law;
        await this.updateAbout({ about: dbData });
        this.showSnackbar("更新しました");
      } catch (e) {
        console.error(e);
        this.showSnackbar("更新に失敗しました");
      }
    },
    showSnackbar: function (text) {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = text;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.v-size--large {
  font-size: 1.4rem;
}
</style>
